<template>
 <v-app>
          <v-navigation-drawer
                    :mini-variant.sync= drawer
                    width="180"
                    mini-variant-width="40"
                    clipped
                  v-model="drawer_visible"
                  expand-on-hover
                    app
                    >  
                        <v-list dense>
                          <v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.destino" router exact>                          
                          <v-list-item-action class="ma-0 pa-0" >
                              <v-icon  >{{ item.icono }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="pa-3">
                              <v-list-item-title  v-text="item.texto" />
                          </v-list-item-content>
                          </v-list-item>
                      </v-list>
                </v-navigation-drawer>   
    <v-app-bar  dense class="white elevation-1"
      :clipped-left="clipped"
      fixed
      app>
      <img :src="logo_rincon" alt="" >  
        <v-app-bar-nav-icon class="ml-3" small @click="cambiarMini()" />             
                        <v-spacer></v-spacer>                        
                            <v-chip   @click=logout small color="#002C51" dense  label  class="  overline px-2 white--text">{{userName}}
                                <v-avatar right tile>
                                       <v-icon small >mdi-logout</v-icon>
                                </v-avatar>
                            </v-chip>
    </v-app-bar>
    <v-main style="background:#EDEDED">
      <v-container fluid>     
         <router-view></router-view>
      </v-container>
    </v-main>
 </v-app>
</template>

<script>
import { mapState } from 'vuex'
  export default { 
    name: 'dashboard-component',
    data () {
    return {
      drawer_visible:true, //que aparezca o no
      clipped: true, //que aparezca abajo del logo de rincon no el cuadrado vacio
      drawer:true, //drawer es que aparezca minimizada(true) o extendida(false)
      fixed: true,
      miniVariant: false,
      logo_rincon: require("@/assets/logo_horizontal.gif"),
      menuItems: [
                    { texto: 'Travel Servicios' , icono: 'mdi-train-car',destino:'listadoServicios'},                    
                    { texto: 'Mis Reservas' , icono: 'mdi-bag-suitcase',destino:'misreservas'},
                    { texto: 'Beneficios' , icono: 'mdi-wallet-giftcard',destino:'beneficios'}                    
                ],   
      
      title: 'App Rincon Club'
    }
  },
  created(){
           if (this.$vuetify.breakpoint.name=='xs'||this.$vuetify.breakpoint.name=='sm'){ // si es mobil no muestra menu al iniciar
              this.drawer_visible=false
           }
  },
  computed:{          
            ...mapState(['autenticado','userName','mini']),
            layout(){
                      return this.$store.getters.layout
            }            
  },
  methods:{
    login(){
                        
    },
    logout(){ 
             this.$store.dispatch('logout')
             this.$router.replace({ path:"/login"})
    },
    cambiarMini(){
                   this.drawer_visible=!this.drawer_visible
                  }
  }
}
</script>