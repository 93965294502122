<template>
  <v-app>
    
     <component :is="layout"/>
  </v-app>
</template>

<script>
//import dashboardHeader from './components/dashboardHeader'
import { mapState } from 'vuex'
import dashboardLayout from './components/layout/dashboard'
import loginLayout from './components/layout/login-Component'
export default {
  components:{ 
              'dashboard-layout':dashboardLayout ,
             //  'gourmet-layout' :gourmetLayout
              'login-layout': loginLayout
             
  },
  data () {
    return {}
  },
  computed:{          
            ...mapState(['autenticado']),
            layout(){
                      //alert(this.$store.getters.layout)
                      return this.$store.getters.layout

            }            
  },
  methods:{
    login(){
                        
    },
    logout(){ 
             this.$store.dispatch('logout')
             this.$router.replace({ path:"/login"})
    }
  }
}
</script>