<template>
    <v-app>
        <v-main style="background:#EDEDED">
            <router-view/>
        </v-main>        
    </v-app>
</template>
<script>

export default {
    name:'login-Component'      
    
}
</script>
