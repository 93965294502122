import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index.js"

Vue.use(VueRouter)

const routes = [
// RinconTravel
  {
    path: '/listadoservicios',
    name: 'listadoServicios',
    component: () => import('../components/rincontravel/listadoServicios.vue'),
    meta: {auth:'true'}
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta:{ ispublic:true}
  },
  {
    path: '/misreservas',
    name: 'misreservas',
    component: () => import('../components/checkin/misreservas.vue'),
    meta: { ispublic:'true'}
  },
  {
    path: '*',
    redirect: '/login'
  },
]

const router = new VueRouter({
                              mode: 'history',
                              base: process.env.BASE_URL,
                              routes
})
router.beforeEach((to, from, next) => {  // redirige a login si requiere autentificacion
  let esrutapublica = (to.meta.ispublic) 
//  alert(esrutapublica) 
  if (esrutapublica) {
      //  alert(esrutapublica)
      } 
      else{
             if (to.meta.auth==='true' && store.state.autenticado==='false'){
                return next("/dashboard/login")
             }                     
  }
  next();
});


export default router
